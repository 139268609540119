import axios from 'axios';

const { spdengine } = window as any;
const apiUrl = process.env.VUE_APP_BORON;

type GetModelImageParams = {
  fileId: string;
  sessionId: string;
  callback: (imageData: string) => any;
};
export async function getModelImage(params: GetModelImageParams) {
  const { fileId, sessionId, callback } = params;
  const res: any = await axios.get(
    `${process.env.VUE_APP_BORON}/Boron/GetRadonPath?fileId=${fileId}`,
    {
      headers: {
        Authorization: `Bearer ${sessionId}`,
      },
    },
  );
  let spdApi3D;
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.top = '0px';
  container.style.left = '0px';
  container.style.width = '665px';
  container.style.height = '407px';
  container.style.visibility = 'hidden';
  document.body.appendChild(container);
  let base64PImg = '';
  const benchId = spdengine.Scott.create({
    container,
    projectId: 'sid',
    dock: 4,
    system: spdengine.SystemType.ST_3D_WSM,
    gisOptions: {},
    useThreads: false,
    tooBarState: true,
    useWasmMemory: true,
    engineSrcPath: '/static/',
    envInitSuccessCB: async () => {
      spdengine.Scott.run(benchId);
      const api = spdengine.Apis.getApi(benchId);
      const api3d = api['3D'];
      spdApi3D = api3d;
      const spdApi = api;
      const pipeMatrix = new spdengine.Matrix43D().compose(
        new spdengine.Vector33D(-100, -994, -1415),
        new spdengine.Quaternion3D().setFromEuler(new spdengine.Euler3D(0, 0, 0)),
        new spdengine.Vector33D(1, 1, 1),
      );
      await spdengine.Scott.load(benchId, {
        url: `${apiUrl}/${res.filePath}/`, // .jdx模型所在后台路径地址
        sessionId,
        workerUrl: '', // 加载模型worker代码所在地址，为了提升模型加载速率，spdengine拉取数据时启用多个worker（pc10个，移动端5个），多线程加载数据，有效加快加载速度
        orgModelId: 1, // 原始model ID，原始model id 考虑到模型复用问题
        newModelId: 1, // 该模型在该项目中的ID号，orgModelId对应的是模型的固有id号，而该id是在该视图中的唯一标识符
        modelMatrix: pipeMatrix, // 模型在该视图中的变化矩阵
      });
      api.PARAMS.eg_enableOutline = true;
      api.PARAMS.sr_enable = true;
      api.PARAMS.rd_continue = true;
      api.PARAMS.eg_mutipleSamples = 8;
      api.PARAMS.eg_maxLevel = 3;
      api.PARAMS.hl_highlightoutlineColor = 0xffff00ff;
      api.PARAMS.hl_enableClick = true; // 开启左键点击
      api.PARAMS.bc_rightMuneEnable = true; // 开启右键菜单
      api.PARAMS.wd_gravityEnable = false;
      api.PARAMS.wd_collisionEnable = false;
      api.PARAMS.wd_moveSpeed = 10;
      api.PARAMS.wd_moveVerticalSpeed = 10;

      api3d.addEventListener('fileloader_geomallloadend', () => { // 整个模型都加载完成之后会触发的事件
        api3d.addEventListener('renderfinish', () => {
          setTimeout(() => {
            base64PImg = spdApi3D.enginePrintScreen();
            callback(base64PImg);
            document.body.removeChild(container);
          }, 500);
        });
      });
      // setTimeout(() => {
      //   base64PImg = spdApi3D.enginePrintScreen();
      //   callback(base64PImg);
      //   document.body.removeChild(container);
      // }, 5000);
    },
  });
}
