
import * as bimOperationQuestionApi from '@/api/BimOperationQuestion';
import Vue from 'vue';
import * as api from '@/api';
import CreateQuestionsCatalogueForm from './create-questions-catalogue-form.vue';

export default Vue.extend({
  props: {
    visible: { type: Boolean },
    editItem: { type: Object },
  },
  components: { CreateQuestionsCatalogueForm },
  methods: {
    async confirm() {
      // 父组件调用子组件：CreateQuestionsCatalogueForm 的方法
      const modalComponent = (this.$refs.form as unknown) as {
        validate: (name: 'createQuestionForm') => false | string;
        reset: (name: 'createQuestionForm') => void;
      };
      if (modalComponent.validate('createQuestionForm') !== false) {
        const inputName = modalComponent.validate('createQuestionForm') as string;
        if (this.editItem) {
          if (this.editItem.name === inputName) {
            this.$emit('update:visible', false);
            return;
          }
          const res = await bimOperationQuestionApi.editCatalogue({
            id: this.editItem.id,
            name: inputName,
            type: bimOperationQuestionApi.JobType.questionBank,
          });
          if (res && !api.error(res)) {
            this.$store.dispatch('bimOperationQuestionModule/getCatalogueList');
            this.$message.success('编辑目录成功');
          } else {
            console.log('编辑目录失败');
          }
        } else {
          const res = await bimOperationQuestionApi.createCatalogue({
            name: inputName,
            type: bimOperationQuestionApi.JobType.questionBank,
          });
          if (res && !api.error(res)) {
            this.$store.dispatch('bimOperationQuestionModule/getCatalogueList');
            this.$message.success('新建目录成功');
          } else {
            console.log('新建目录失败');
          }
        }
        // modalComponent.reset("createQuestionForm");
        this.$emit('update:visible', false);
      }
    },
  },
});
