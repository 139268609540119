
import Vue from "vue";
import SearchInput from "@/components/SearchInput.vue";
import QuestionConfigure from "../question-configure/question-configure.vue";

export default Vue.extend({
  components: { SearchInput, QuestionConfigure },
  methods: {
    searchInputChange(value) {
      this.$store.commit("bimOperationQuestionModule/setSearchText", value);
    },
    createQuestion() {
      this.$store.commit("bimOperationQuestionModule/resetAll");
      this.$store.commit("bimOperationQuestionModule/setConfigureVisible", true);
    }
  }
});
