var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
  'wrapper',
  _vm.$store.state.bimOperationQuestionModule.selectedCatalogue &&
    _vm.$store.state.bimOperationQuestionModule.selectedCatalogue.id === _vm.item.id
    ? 'selected'
    : ''
],on:{"click":_vm.selectCatalogue}},[_c('span',{staticClass:"catalogueListItemName"},[_c('Ellipsis',{attrs:{"content":_vm.item.name,"refName":_vm.item.name + _vm.item.id,"placement":'right',"popperClass":'catalogueListItemTooltip'}})],1),_c('span',{staticClass:"creatorName"},[_c('Ellipsis',{attrs:{"content":`(${_vm.item.createNickName})`,"refName":_vm.item.createNickName + _vm.item.id,"placement":'right'}})],1),(_vm.isOwn)?_c('i',{staticClass:"el-icon-edit-outline editIcon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editItem.apply(null, arguments)}}}):_vm._e(),(_vm.isOwn)?_c('i',{staticClass:"el-icon-delete deleteIcon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteItem.apply(null, arguments)}}}):_vm._e(),_c('CreateEditQuestionModal',{attrs:{"visible":_vm.dialogVisible,"editItem":_vm.item},on:{"update:visible":function($event){_vm.dialogVisible=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }