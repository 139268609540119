
import Vue from 'vue';
import CreateQuestionsCatalogue from '@/features/bim-operation-question/create-questions-catalogue/create-questions-catalogue.vue';
import CatalogueListItem from '@/features/bim-operation-question/catalogue-list-item/catalogue-list-item.vue';
import LeftRightLayout from '@/layout/left-right-layout/left-right-layout.vue';
import TopBotLayout from '@/layout/top-bot-layout/top-bot-layout.vue';
import ToolHeader from '@/features/bim-operation-question/tool-header/tool-header.vue';
import ModelTable from '@/features/bim-operation-question/model-table/model-table.vue';

export default Vue.extend({
  components: {
    CreateQuestionsCatalogue,
    CatalogueListItem,
    LeftRightLayout,
    TopBotLayout,
    ToolHeader,
    ModelTable,
  },
  computed: {
    catalogueList(): any[] {
      return this.sortList(this.$store.state.bimOperationQuestionModule.catalogueList);
    },
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: 'BIM实操题',
        path: 'BIMPracticalOperationQuestion',
      },
    ]);
    this.$store.dispatch('bimOperationQuestionModule/getCatalogueList').then(() => {
      this.$store.commit(
        'bimOperationQuestionModule/setSelectedCatalogue',
        this.$store.state.bimOperationQuestionModule.catalogueList[0],
      );
      this.$store.dispatch('bimOperationQuestionModule/getQuestionList', {
        id: this.$store.state.bimOperationQuestionModule.catalogueList[0]?.id,
      });
    });
  },
});
