import {
  isEqual, max, min, replace, startsWith, trimStart, uniq, uniqWith,
} from 'lodash-es'
import type { Attributes } from './types'
import DefaultScoringRubrics from '@/assets/default-scoring-rubric.json'
import DefaultComponentRules from '@/assets/default-component-prop-template.json'
import { floatObj } from '@/utils/floatObj'

interface Component {
  type: string
  name: string
}
const processNameConfigure = {
  梁: 3,
  柱: 3,
  基础: 2,
  板: 3,
  楼梯: 2,
  墙体: 3,
  门: 2,
  窗: 2,
  屋面: 2,
  栏杆扶手: 3,
  顶部扶栏: 3,
  坡道: 2,
  散水: 2,
  排水沟: 2,
  台阶: 2,
  雨蓬: 3,
}
export const typeCodeMap = {
  L: '梁',
  JCL: '基础梁',
  KL: '框架梁',
  WL: '屋面梁',

  WKL: '屋面框架梁',

  TL: '梯梁',

  QL: '圈梁',

  XL: '悬挑梁',

  LL: '连梁',

  DL: '地梁',

  GL: '过梁',

  KZL: '框支梁',

  BKL: '边框梁',

  AL: '暗梁',

  PL: '平台梁',

  Z: '柱',

  JZ: '矩形柱',

  YZ: '圆形柱',
  TZ: '梯柱',

  YXZ: '异形柱',

  GZ: '构造柱',

  AZ: '暗柱',

  KZ: '框架柱',

  KZZ: '框支柱',

  J: '基础',

  ZH: '桩',

  CT: '承台',

  ZJ: '桩基础',

  DJ: '独立基础',

  TJ: '条形基础',

  FJ: '筏板基础',

  JJ: '结构基础',

  B: '板',

  DB: '底板',

  JB: '结构板',

  TB: '挑板',

  DM: '地面',

  LB: '楼板',

  GB: '盖板',

  WB: '屋面板',

  XTB: '悬挑板',

  PTB: '平台板',

  Q: '墙',

  JLQ: '剪力墙',
  DQ: '挡土墙',

  YP: '雨蓬',

  M: '门',

  FDM: '防盗门',

  XM: '旋转门',

  LM: '玻璃门',

  DKM: '单开门',

  SM: '双开门',

  MLC: '门联窗',

  JLM: '卷帘门',

  TM: '推拉门',

  FM: '防火门',

  MD: '门洞',

  ZMM: '子母门',

  C: '窗',

  ZC: '组合窗',

  GC: '固定窗',

  TC: '推拉窗',

  BYC: '百叶窗',

  FC: '防火窗',

  GSC: '格栅窗',
  PC: '飘窗',
  LC: '落地窗',
}
const processName = (originName: string, type: string) => {
  const arr = originName.split('_')
  const bigType = DefaultScoringRubrics.find(v => v.matchKeys.split('、').includes(type))
    ?.name
  if (!bigType)
    return originName

  return arr.filter((v, index) => index < (processNameConfigure[bigType] || 2)).join('_')
}

const getRealType = (type: string) => {
  if (!type)
    return ''
  const isAllAlphabet = /^[a-zA-Z0-9*]+$/
  if (isAllAlphabet.test(type)) {
    const key = Object.keys(typeCodeMap)
      .sort((a, b) => b.length - a.length)
      .find(v => type.includes(v))!
    return getRealType(typeCodeMap[key])
  }
  const allMatchedKeys = DefaultScoringRubrics.map(v => v.matchKeys.split('、'))
    ?.flat(1)
    ?.sort((a, b) => b.length - a.length)
  const key = allMatchedKeys?.find(v => type.includes(v)) || ''
  return key
}

/**
 * 处理type，去掉族_，并去默认配置中找到对应的类型
 * @param name
 * @returns
 */
const getType = (name: string) => {
  let res = name
  res = res
    .split('_')
    .filter((v, i) => i !== 0 && i !== 1)
    .join('_')
  res = getRealType(res.split('_')?.[0])
  return res
}

/**
 * 处理name，去掉族_、去掉类型、根据其类型拿下划线前两块或三块
 * @param name
 * @returns
 */
const getName = (name: string, type: string) => {
  let res = name
  res = res
    .split('_')
    .filter((v, i) => i !== 0 && i !== 1)
    .join('_')
  res = processName(res, type)
  return res
}

/**
 * 获取非轴网的构件列表
 * @param data 接口返回的所有的instances，包括了轴网，构件
 * @returns 返回除了轴网的所有构件，拿到对应的类型和name
 */
export const getAllComponents = (
  data: { id: number; name: string; instanceType: string }[],
): Component[] => {
  const res = data
    .filter(v => v.instanceType !== 'OST_Grids')
    .map(v => ({
      type: getType(v.name),
      name: getName(v.name, getType(v.name)),
    }))
    .sort((a, b) => a.name.localeCompare(b.name, 'zh-CN', { numeric: true }))
  return res
}

const getAllComponentsWithAttributes = (
  data: { id: number; name: string; instanceType: string; propertySets: any[] }[],
) => {
  const res = data
    .filter(v => v.instanceType !== 'OST_Grids')
    .map(v => ({
      ...v,
      originName: v.name
        .split('_')
        .filter((v, i) => i !== 0 && i !== 1)
        .join('_'),
      name: getName(v.name, getType(v.name)),
      propertySets: v.propertySets
        .map(v => v.properties.map(v => ({
          innerName: v.innerName,
          displayValue: v?.displayValue,
          valueType: v.valueType,
        })))
        .flat(),
    }))
  return res
}
const getExistedKeys = (typeArr: Component[]) => uniq(typeArr.map(v => getRealType(v.type))).filter(Boolean)

const getAttributes = (type: string) => DefaultComponentRules.find(v => v.componentType === type)?.attributes || []

export const generateScoringRubrics = (instances) => {
  const allComponents = getAllComponents(instances)
  console.log('allComponents', allComponents)
  const existedKeys = getExistedKeys(allComponents)
  const existedBigTypes = DefaultScoringRubrics.filter(v => existedKeys.some(e => v.matchKeys.split('、').includes(e))).map(({ name }) => name)
  const uniqComponents = uniqWith(allComponents, isEqual).filter(v => Boolean(v.type))
  let totalScoreCache = 0
  const map2 = existedBigTypes.map((v, index) => {
    const componentsOfCurrentBigType = uniqComponents.filter(u => DefaultScoringRubrics.find(d => d.name === v)
      ?.matchKeys.split('、')
      .includes(u.type))
    let totalScore = 0
    if (index !== existedBigTypes.length - 1) {
      totalScoreCache += Math.round(
        (componentsOfCurrentBigType.length * 100) / uniqComponents.length,
      )
      totalScore = Math.round((componentsOfCurrentBigType.length * 100) / uniqComponents.length)
    }
    else {
      totalScore = 100 - totalScoreCache
    }
    return {
      key: v,
      components: componentsOfCurrentBigType,
      totalScore,
    }
  })
  return DefaultScoringRubrics.map(v => ({
    ...v,
    isChecked: map2.some(({ key }) => v.name === key),
    score: map2.find(({ key, totalScore }) => v.name === key)?.totalScore || 0,
  }))
}

interface MapWithCount {
  name: string
  score: number
  isChecked?: boolean
  matchKeys: string
  sameBigTypeComponentCount?: number
}
export const generateComponentRules = (instances) => {
  const allComponents = getAllComponents(instances)
  const uniqComponents = uniqWith(allComponents, isEqual).filter(v => Boolean(v.type))
  // 解析出来的已有的小类
  const existedKeys = getExistedKeys(allComponents)
  // 根据小类解析出已有的大类
  const existedBigTypes = DefaultScoringRubrics.filter(v => existedKeys.some(e => v.matchKeys.split('、').includes(e))).map(({ name }) => name)
  console.log('existedBigTypes', existedBigTypes)

  let totalScoreCache = 0
  const map2 = existedBigTypes.map((v, index) => {
    const componentsOfCurrentBigType = uniqComponents.filter(u => DefaultScoringRubrics.find(d => d.name === v)
      ?.matchKeys.split('、')
      .includes(u.type))
    let totalScore = 0
    console.log(totalScoreCache)

    if (index !== existedBigTypes.length - 1) {
      totalScoreCache = floatObj.add(
        totalScoreCache,
        Math.round(
          floatObj.divide(
            floatObj.multiply(componentsOfCurrentBigType.length, 100),
            uniqComponents.length,
          ),
        ),
      )
      totalScore = Math.round(
        floatObj.divide(
          floatObj.multiply(componentsOfCurrentBigType.length, 100),
          uniqComponents.length,
        ),
      )
    }
    else {
      totalScore = floatObj.subtract(100, totalScoreCache)
    }
    return {
      key: v,
      components: componentsOfCurrentBigType,
      totalScore,
    }
  })
  // 对每个分过类的components给分数比，同样取整，第一个补完
  const map3 = map2.map((item) => {
    let scoreCache = 0
    const components = item.components.map((c, index) => {
      let score = 0
      if (index !== item.components.length - 1) {
        scoreCache = floatObj
          .add(floatObj.divide(item.totalScore, item.components.length), scoreCache)
          .toFixed(2)
        score = floatObj.divide(item.totalScore, item.components.length).toFixed(2)
      }
      else {
        score = floatObj.subtract(item.totalScore, scoreCache)
      }
      return { ...c, score }
    })
    return { ...item, components }
  })
  console.log('map3', map3)

  // 拿出所有components
  const componentsWithScore = map3.map(m => [...m.components.map(c => ({ ...c, baseType: m.key }))]).flat()
  console.log('componentsWithScore', componentsWithScore)
  // 给每个component添加属性，ishidden这些类型
  const res = componentsWithScore.map(v => ({
    componentType: v.type,
    componentName: v.name,
    attributes: getAttributes(v.type) as Attributes[],
    score: v.score,
    isHidden: false,
    baseType: v.baseType,
  }))
  const allComponentsWithAttributes = getAllComponentsWithAttributes(instances)
  for (let i = 0; i < res.length; i++) {
    const { componentName } = res[i]
    const attrs = res[i].attributes
      .map((attr) => {
        const attrName = attr.name

        const currentComponents = allComponentsWithAttributes.filter(v => v.name === componentName)

        const allAttributes = currentComponents.map(v => v.propertySets.find(property => property.innerName === attrName))
        if (attrName === '结构材质')
          console.log(allAttributes)

        if (attrName === '类型') {
          return {
            ...attr,
            type: 2,
            values: uniq(currentComponents.map(v => v.originName)),
          }
        }
        if (!allAttributes.length || allAttributes.some(v => !v)) {
          return {
            ...attr,
            type: 0,
          }
        }
        if (allAttributes[0].valueType === 2) {
          const allNum = allAttributes.map((v) => {
            const num = v?.displayValue.replace(/[^\d|^\.|^\-]/g, '')
            if (!num)
              return 0

            return parseFloat(num)
          })
          const max = Math.round(Math.max(...allNum)) + 1
          const min = Math.round(Math.min(...allNum)) - 1
          const _middleValue = (max + min) / 2
          const _precesion = max - _middleValue
          return {
            ...attr,
            precesion: _precesion,
            middleValue: _middleValue,
          }
        }
        return {
          ...attr,
          values: uniq(allAttributes.map(v => v?.displayValue)),
          type: 2,
        }
      })
      .filter(v => v.type !== 0)
    const total = attrs.reduce((a, b) => a + b.percent, 0)
    attrs[0].percent = attrs[0].percent + (100 - total)
    res[i].attributes = attrs
  }
  console.log('res', res)

  return res
}
