
import SvgIcon from "@/components/SvgIcon.vue";
import Vue from "vue";
import UploadModel from "./upload-model.vue";
import QuantitativeRuleStatistics from "./quantitative-rule-statistics.vue";
import AttributeRuleConfiguration from "./attribute-rule-configuration.vue";
import WeightAllocation from "./weight-allocation.vue";

export default Vue.extend({
  components: {
    SvgIcon,
    UploadModel,
    QuantitativeRuleStatistics,
    AttributeRuleConfiguration,
    WeightAllocation
  },
  data() {
    return {
      stepsData: [
        { title: "上传模型", icon: "process_icon_upload", key: 1 },
        { title: "权重配置", icon: "process_icon_pass", key: 2 },
        { title: "数量规则统计", icon: "process_icon_number", key: 3 },
        { title: "属性规则配置", icon: "process_icon_attribute", key: 4 }
      ]
    };
  },
  methods: {
    finishConfiguration() {
      this.$store.commit("bimOperationQuestionModule/setConfigureVisible", false);
    },
    exit() {
      this.$alert("未保存配置结果，确定退出当前界面？", "退出", {
        type: "warning",
        showClose: false,
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonClass: "confirmBtn"
      }).then(() => {
        this.$store.commit("bimOperationQuestionModule/setConfigureVisible", false);
      });
    },
    jumpStep(index) {
      this.$store.commit("bimOperationQuestionModule/setCurrentStep", index);
    }
  }
});
