
import Vue, { PropType } from 'vue';
import { cloneDeep, remove } from 'lodash-es';
import DefaultScoringRubrics from '@/assets/default-scoring-rubric.json';
import DefaultComponentRules from '@/assets/default-component-prop-template.json';
import * as api from '@/api';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { getComponents } from '@/api/Jobs';
import { floatObj } from '@/utils/floatObj';
import {
  Attributes,
  ComponentRule,
  ComponentType,
  ComponentTypeEnum,
  Rules,
  ScoringRubric,
  Task,
  TaskFile,
} from '../types';
import {
  generateComponentRules,
  generateScoringRubrics,
  typeCodeMap,
} from '../generate-rule-from-model';

export default Vue.extend({
  props: {
    rulesContent: { type: String as PropType<string> },
    typeContent: { type: String as PropType<string> },
    standardFile: { type: Object as PropType<Partial<TaskFile>> },
    task: { type: Object as PropType<Task> },
  },
  data() {
    return {
      defaultComponentRules: [...cloneDeep(DefaultComponentRules)],
      scoringRubrics: ((safeJsonParse(this.typeContent).scoringRubric || [
        ...cloneDeep(DefaultScoringRubrics),
      ]) as ScoringRubric[]).map((v) => ({ ...v, score: floatObj.multiply(v.score, 100) })),
      componentRules: cloneDeep(
        ((safeJsonParse(this.typeContent).componentRules || []) as ComponentRule[]).map((v) => ({
          ...v,
          score: floatObj.multiply(v.score, 100),
        })),
      ),
      currentEditScoringRubric: {} as ScoringRubric,
      rules: [] as Rules[],
      innerDrawerVisible: false,
      currentEditComponentRule: {} as ComponentRule,
      attributeEditModalVisible: false,
      typeCheck: 0,
      inputVisible: false,
      inputValue: '',
      configureFormData: {
        name: undefined,
        type: 0,
        sample: undefined,
        middleValue: undefined,
        precesion: undefined,
        values: [],
        candidates: [],
      } as Partial<Attributes>,
      currentEditAttribute: {} as Partial<Attributes>,
      loading: false,
    };
  },
  computed: {
    isAllChecked(): boolean {
      return this.scoringRubrics.every((v) => Boolean(v.isChecked));
    },
    isIndeterminate(): boolean {
      return this.checkedCount > 0 && this.checkedCount < this.scoringRubrics.length;
    },
    checkedCount(): number {
      return this.scoringRubrics.filter((v) => Boolean(v.isChecked)).length;
    },
    checkedScore(): number {
      return this.scoringRubrics.reduce((a, b) => {
        if (b.isChecked) {
          return floatObj.add(a, b.score);
        }
        return a;
      }, 0);
    },
    currentEditScoringRubricMatchedKeys(): string[] {
      return this.currentEditScoringRubric.matchKeys?.split('、');
    },
    currentEditComponentRulesList: {
      get(): ComponentRule[] {
        return [
          ...this.componentRules.filter((v) => this.currentEditScoringRubricMatchedKeys?.includes(v.componentType)),
        ];
      },
      set(newList: ComponentRule[]): ComponentRule[] {
        const newArr = newList;
        const newComponentRules = this.componentRules.map((v) => {
          if (
            newArr.some(
              ({ componentName, componentType }) => componentName === v.componentName && componentType === v.componentType,
            )
          ) {
            const rightItem = {
              ...newArr.find(
                ({ componentName, componentType }) => componentName === v.componentName && componentType === v.componentType,
              )!,
            };
            remove(
              newArr,
              ({ componentName, componentType }) => componentName === v.componentName && componentType === v.componentType,
            );
            return rightItem;
          }
          return v;
        });
        return [...newComponentRules, ...newArr];
      },
    },
    isHundredPercent(): boolean {
      return this.totalPercent === 100;
    },
    totalPercent(): number {
      return this.currentEditComponentRule.attributes?.reduce(
        (a, b) => floatObj.add(a, b.percent),
        0
      );
    },
  },
  methods: {
    getCount(name): number {
      return this.componentRules.filter((v) => v.baseType === name).length;
    },
    handleEditClick(name) {
      this.currentEditScoringRubric = this.scoringRubrics.find((v) => v.name === name)!;
      this.$emit('onEditClick');
    },
    handleComponentRuleEdit(item: ComponentRule) {
      this.currentEditComponentRule = this.componentRules.find(
        (v) => v.componentName === item.componentName,
      )!;
      this.innerDrawerVisible = true;
    },
    handleCloseTag(tag) {
      this.configureFormData.values?.splice(this.configureFormData.values?.indexOf(tag), 1);
      this.configureFormData.candidates?.splice(this.configureFormData.candidates?.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        (this.$refs.saveTagInput as any).$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const { inputValue } = this;
      if (inputValue) {
        this.configureFormData.values?.push(inputValue);
        this.configureFormData.candidates?.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    deleteAllTag() {
      this.configureFormData.values = [];
    },
    handleConfigure(data: Attributes) {
      this.currentEditAttribute = data;
      this.configureFormData = { ...data };
      this.attributeEditModalVisible = true;
    },
    handleConfigureConfirm() {
      if (this.configureFormData.type === ComponentTypeEnum.any) {
        this.configureFormData.values = undefined;
        this.configureFormData.candidates = undefined;
        this.configureFormData.middleValue = undefined;
        this.configureFormData.precesion = undefined;
      }
      if (this.configureFormData.type === ComponentTypeEnum.number) {
        this.configureFormData.values = undefined;
        this.configureFormData.candidates = undefined;
      }
      if (this.configureFormData.type === ComponentTypeEnum.text) {
        this.configureFormData.middleValue = undefined;
        this.configureFormData.precesion = undefined;
      }
      this.currentEditComponentRule.attributes = this.currentEditComponentRule.attributes.map((v) => {
        if (v.name === this.currentEditAttribute.name) {
          return {
            ...(this.configureFormData as Attributes),
            percent: this.currentEditAttribute.percent!,
          };
        }
        return v;
      });
      this.attributeEditModalVisible = false;
    },
    handleInnerDrawerClose() {
      if (!this.currentEditComponentRule.componentName) {
        this.$message.error('构件名称不能为空！请重新输入！');
        return;
      }
      if (
        this.componentRules.filter(
          (v) => v.componentName === this.currentEditComponentRule.componentName
            && v.componentType === this.currentEditComponentRule.componentType,
        ).length > 1
      ) {
        this.$message.warning('已有此构件名称！请重新输入！');
        return;
      }
      if (!this.isHundredPercent) {
        this.$message.error('占分比例不为100%！请重新输入！');
        return;
      }
      this.currentEditComponentRulesList = this.currentEditComponentRulesList.map((v) => {
        if (v.componentType === this.currentEditComponentRule.componentType) {
          return this.currentEditComponentRule;
        }
        return v;
      });
      this.innerDrawerVisible = false;
    },
    handleAddComponent(componentType) {
      console.log(componentType);

      const item = {
        baseType: this.currentEditScoringRubric.name,
        componentType,
        score: 0,
        attributes: cloneDeep(
          (this.defaultComponentRules as ComponentRule[]).find(
            (v) => v.componentType === componentType,
          )?.attributes || [],
        ),
        componentName: `${componentType}`,
        isHidden: false,
      };
      this.componentRules.push(item);
      this.currentEditComponentRule = this.componentRules[this.componentRules.length - 1];
      this.updateScore(item);
      this.innerDrawerVisible = true;
    },
    addAttribute() {
      this.currentEditComponentRule.attributes.push({
        name: '属性',
        percent: 0,
        type: 0,
        values: [],
      });
    },
    handleAttributeDelete(data: Attributes, index) {
      console.log('删除属性');
      this.currentEditComponentRule.attributes = this.currentEditComponentRule.attributes.filter(
        (v, i) => v.name !== data.name,
      );
    },
    updateScore(item) {
      console.log(item);

      console.log(this.currentEditComponentRulesList.map((v) => v.score));
      this.scoringRubrics = this.scoringRubrics.map((v) => {
        if (v.matchKeys.includes(item.componentType)) {
          return {
            ...v,
            score: this.currentEditComponentRulesList.reduce((a, b) => floatObj.add(a, b.score), 0),
          };
        }
        return v;
      });
      this.currentEditScoringRubric.score = this.currentEditComponentRulesList.reduce(
        (a, b) => floatObj.add(a, b.score),
        0,
      );
    },

    handleComponentScoreChange(val, item: ComponentRule) {
      this.updateScore(item);
    },
    handleComponentDelete(item: ComponentRule) {
      this.componentRules = this.componentRules.filter(
        ({ componentName }) => item.componentName !== componentName,
      );
      this.updateScore(item);
    },
    handleSave() {
      const params = {
        rulesContent: JSON.stringify({
          Rules: this.componentRules.map((v) => ({
            ElementNames: [v.componentName],
            NumAttrList: v.attributes
              .filter(({ type }) => type === ComponentTypeEnum.number)
              .map(({
 name, precesion, middleValue, percent,
}) => ({
                Name: name,
                Precesion: precesion,
                Values: [middleValue],
                percent,
              })),
            TextAttrList: v.attributes
              .filter(({ type }) => type !== ComponentTypeEnum.number)
              .map(({
 name, values, percent, type,
}) => ({
                Name: name,
                Values: type === ComponentTypeEnum.any ? [] : values,
                percent,
              })),
            score: floatObj.divide(v.score, 100),
            TotalMatch: -1,
          })),
        }),
        typeContent: JSON.stringify({
          scoringRubric: this.scoringRubrics.map((v) => ({
            ...v,
            score: floatObj.divide(v.score, 100),
          })),
          componentRules: this.componentRules.map((v) => ({
            ...v,
            score: floatObj.divide(v.score, 100).toFixed(4),
            baseType: DefaultScoringRubrics.find(({ matchKeys }) => matchKeys.split('、').some((key) => key === v.componentType))?.name,
          })),
        }),
        taskId: this.task?.id,
      };
      this.$emit('onSaveRules', this.checkedScore === 100 ? params : false);
    },
    handleAllCheckChange(e) {
      this.scoringRubrics = this.scoringRubrics.map((v) => ({ ...v, isChecked: e }));
    },
    async generateFromModel() {
      if (!this.standardFile?.uploadFile?.fileId) {
        this.$message.error('请先上传标准模型！');
        return;
      }
      this.loading = true;
      try {
        const res: any = await getComponents(
          { fileId: this.standardFile.uploadFile.fileId },
          this.$store.state.sessionId,
        );
        console.log(res);

        if (res) {
          const flag = await new Promise((resolve) => {
            this.scoringRubrics = generateScoringRubrics(res.instances) as ScoringRubric[];
            this.componentRules = generateComponentRules(res.instances) as ComponentRule[];
            resolve(true);
          });
          if (flag) {
            this.loading = false;
            this.currentEditAttribute = {};
            this.currentEditComponentRule = {} as ComponentRule;
            this.currentEditComponentRulesList = [];
            this.currentEditScoringRubric = {} as ScoringRubric;
          }
        }
      } catch (error) {
        console.log(error);
        this.$message.error('网络有问题，请重试！');
        this.loading = false;
      }
    },
  },
  filters: {
    formatType(val) {
      return ComponentType[val] || '无';
    },
    formatRule(val: Attributes) {
      if (val) {
        if (val.type === ComponentTypeEnum.any) {
          return '任意值';
        }
        if (val.type === ComponentTypeEnum.number) {
          return `${val.middleValue} ±${val.precesion}    (${val.middleValue!
            - val.precesion!} ~  ${val.middleValue! + val.precesion!})`;
        }
      }
    },
    addCode(val) {
      if (!val) return '';
      const arr = val.split('、').map((v) => {
        const key = Object.keys(typeCodeMap).find((key) => typeCodeMap[key] === v);
        if (key) {
          return `${typeCodeMap[key]}（${key}）`;
        }
        return v;
      });
      return arr.join('、');
    },
  },
  watch: {
    typeContent: {
      handler() {
        this.scoringRubrics = ((this.typeContent
          ? JSON.parse(this.typeContent).scoringRubric
          : [...cloneDeep(DefaultScoringRubrics)]) as ScoringRubric[]).map((v) => ({
          ...v,
          score: (v.score * 1000) / 10,
        }));
        this.componentRules = ((this.typeContent
          ? JSON.parse(this.typeContent).componentRules
          : []) as ComponentRule[]).map((v) => ({ ...v, score: (v.score * 1000) / 10 }));
      },
      deep: true,
    },
    componentRules: {
      handler(val) {
        console.log(val);
      },
      deep: true,
    },
  },
});
