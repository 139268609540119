
import Vue from "vue";
import CreateEditQuestionModal from "../create-edit-question-modal/create-edit-question-modal.vue";

export default Vue.extend({
  components: { CreateEditQuestionModal },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {}
});
