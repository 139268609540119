var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"upload_model_wrapper"},[_c('div',{staticClass:"title"},[_vm._v("请上传标准模型")]),_c('div',{staticClass:"upload_wrapper"},[_c('span',{staticClass:"label"},[_vm._v("上传"+_vm._s(_vm.$store.state.bimOperationQuestionModule.uploadedFile.isUploaded ? "状态" : "模型"))]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[(!_vm.$store.state.bimOperationQuestionModule.uploadedFile.isUploaded)?_c('el-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"#","multiple":false,"auto-upload":false,"show-file-list":false,"on-change":_vm.uploadModel}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("（仅能选择一份文件）")])]):_c('div',{staticStyle:{"display":"flex","width":"400px","align-items":"center"}},[_c('svg-icon',{attrs:{"className":'accessoryIcon',"iconClass":'accessory'}}),_c('div',{staticStyle:{"display":"flex","align-items":"center","flex":"1","position":"relative"}},[_c('div',{staticClass:"progress_bg"}),_c('div',{staticClass:"progress",style:(`transform:scaleX(${parseFloat(
              _vm.$store.state.bimOperationQuestionModule.uploadedFile.percentage
            ) / 100});background-color:${_vm.$store.state.bimOperationQuestionModule.uploadedFile.error ? '#FF8080' : '#02D6DC'
            }`)}),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.$store.state.bimOperationQuestionModule.uploadedFile.fileName))]),_c('span',{style:(`margin-left:8px;color:${_vm.$store.state.bimOperationQuestionModule.uploadedFile.error
              ? '#FF8080'
              : _vm.$store.state.bimOperationQuestionModule.uploadedFile.percentage === '100'
                ? '#42B122'
                : undefined
            }`)},[_vm._v(_vm._s(_vm.progress))]),(_vm.$store.state.bimOperationQuestionModule.uploadedFile.error)?_c('svg-icon',{staticStyle:{"margin-left":"auto"},attrs:{"className":'refreshIcon',"iconClass":'refresh'}}):_vm._e(),_c('svg-icon',{style:(`margin-left:${_vm.$store.state.bimOperationQuestionModule.uploadedFile.error ? '8px' : 'auto'
            };`),attrs:{"className":'deleteIcon',"iconClass":'add'},on:{"click":_vm.deleteModel}})],1)],1),(_vm.$store.state.bimOperationQuestionModule.uploadedFile.percentage === '100')?_c('div',{staticClass:"convertWrapper"},[_c('svg-icon',{attrs:{"className":'convertIcon',"iconClass":'sandclock'}}),_c('div',{staticClass:"convertContent"},[_c('div',{staticClass:"convertTitle"},[_c('span',{style:(`color: ${_vm.$store.state.bimOperationQuestionModule.uploadedFile.convertError
                ? '#FF8080'
                : '#999'
              }`)},[_vm._v(" "+_vm._s(_vm.$store.state.bimOperationQuestionModule.uploadedFile.convertError ? "模型转换失败" : _vm.$store.state.bimOperationQuestionModule.uploadedFile.convertProgress === 100 ? "模型转换完成" : "模型转换中...")+" ")]),(_vm.$store.state.bimOperationQuestionModule.uploadedFile.convertError)?_c('svg-icon',{attrs:{"iconClass":'refresh'},on:{"click":_vm.convertModel}}):_vm._e()],1),_c('div',{staticClass:"converProgress_bg"}),_c('div',{staticClass:"converProgress",style:(`transform:scaleX(${_vm.$store.state.bimOperationQuestionModule.uploadedFile
              .convertProgress / 100})`)})])],1):_vm._e(),(
        _vm.$store.state.bimOperationQuestionModule.uploadedFile.percentage === '100' &&
        _vm.$store.state.bimOperationQuestionModule.uploadedFile.convertProgress === 100
      )?_c('div',{staticClass:"modelImageAndName"},[(_vm.$store.state.bimOperationQuestionModule.uploadedFile.modelImage)?_c('img',{staticClass:"modelImage",attrs:{"src":_vm.$store.state.bimOperationQuestionModule.uploadedFile.modelImage,"alt":""}}):_c('div',{staticClass:"modelImageAndNameLoading"},[_c('i',{staticClass:"el-icon-loading loadingIcon"}),_vm._v(" "),_c('span',[_vm._v("图片加载中")])]),_c('div',{staticClass:"nameWrapper"},[_c('span',{staticClass:"nameWrapper_label"},[_vm._v("模型名称：")]),_c('el-input',{staticClass:"nameInput",attrs:{"value":_vm.$store.state.bimOperationQuestionModule.uploadedFile.fileName},on:{"input":_vm.nameInput}})],1)]):_vm._e(),(
        _vm.$store.state.bimOperationQuestionModule.uploadedFile.convertProgress === 100 &&
        _vm.$store.state.bimOperationQuestionModule.uploadedFile.modelImage
      )?_c('el-button',{staticStyle:{"margin-top":"36px","align-self":"center"},attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }