
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    handleNumberChange(e) {
      // 四舍五入
      const value = Math.round(e);
      this.$store.commit('bimOperationQuestionModule/setAllocationCountValue', value);
    },
    commit() {
      this.$store
        .dispatch('bimOperationQuestionModule/saveWeightAllocation')
        .then((saveQuantitativeRuleSuccess) => {
          if (saveQuantitativeRuleSuccess) {
            this.$emit('toStep', 3);
          }
        });
    },
  },
});
