
import { getComponentsWithoutProperty } from "@/api/Jobs";
import { getAllComponents } from "@/features/collage/generate-rule-from-model";
import { lookArray } from "@/utils/look-array";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.generateQuantity();
  },
  methods: {
    async generateQuantity() {
      try {
        const res: any = await getComponentsWithoutProperty(
          { fileId: this.$store.state.bimOperationQuestionModule.uploadedFile.fileId },
          this.$store.state.sessionId
        );
        if (res) {
          const flag = await new Promise(resolve => {
            const components = getAllComponents(res.instances);
            this.$store.commit(
              "bimOperationQuestionModule/setStandardComponentsNumber",
              components.length
            );
            resolve(true);
          });
          if (flag) {
            this.loading = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.$message.error("网络有问题，请重试！");
      }
    },
    checkAndNext() {
      const arr = [...this.$store.state.bimOperationQuestionModule.countErrorAndGrade];
      const isAllLessStandardNumber = arr.every(
        v => v.errorValue <= this.$store.state.bimOperationQuestionModule.standardComponentsNumber
      );
      if (!isAllLessStandardNumber) {
        this.$message.error("误差值不可大于构建数量标准值!");
        return;
      }
      arr.sort((a, b) => a.errorValue - b.errorValue);
      const gradeSort = lookArray(arr.map(v => v.grade));
      if (gradeSort !== -1) {
        this.$message.error("输入数值错误，当误差越大，得分应越少");
        return;
      } else {
        this.$store
          .dispatch("bimOperationQuestionModule/saveQuantitativeRule")
          .then(saveQuantitativeRuleSuccess => {
            if (saveQuantitativeRuleSuccess) {
              this.$emit("toStep", 4);
            }
          });
      }
    }
  }
});
