
import { convertModel, getConvertProgress } from '@/api/Jobs';
import SvgIcon from '@/components/SvgIcon.vue';
import { getModelImage } from '@/utils/get-model-image';
import { uploadFile } from '@/utils/upload-file';
import axios from 'axios';
import ModelView from '@/components/model-view/index.vue';
import Vue from 'vue';

const { spdengine } = window as any;
const initialEmptyFile = {
  fileName: null,
  percentage: '0',
  filePath: null,
  fileId: '',
  error: null,
};
export default Vue.extend({
  components: { SvgIcon, ModelView },
  data() {
    return {};
  },
  computed: {
    progress(): any {
      if (!this.$store.state.bimOperationQuestionModule.uploadedFile.isUploaded) {
        return '';
      } if (this.$store.state.bimOperationQuestionModule.uploadedFile.error) {
        return '（ 上传失败 ）';
      } if (this.$store.state.bimOperationQuestionModule.uploadedFile.percentage === '100') {
        return '（ 上传成功 ）';
      }
      return `（ 上传进度${this.$store.state.bimOperationQuestionModule.uploadedFile.percentage}% ）`;
    },
  },
  methods: {
    uploadModel(_file) {
      const file = _file.raw;
      const index = file.name.lastIndexOf('.');
      const type: string = file.name.slice(index);
      const uploadableExtensions = ['.rvt'];
      if (!uploadableExtensions.some((v) => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${uploadableExtensions.join(' ')}文件`);
        return;
      }
      this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
        fileName: file.name.split('.')[0],
        fileSize: file.size,
        isUploaded: true,
        fileExtension: 'rvt',
      });
      const setFile = (data) => {
        const {
          percentage, filePath, fileId, error,
        } = data;
        this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
          percentage:
            percentage ?? this.$store.state.bimOperationQuestionModule.uploadedFile.percentage,
          filePath: filePath ?? this.$store.state.bimOperationQuestionModule.uploadedFile.filePath,
          fileId: fileId ?? this.$store.state.bimOperationQuestionModule.uploadedFile.fileId,
          error: error ?? this.$store.state.bimOperationQuestionModule.uploadedFile.error,
        });
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
    deleteModel() {
      this.$store.commit('bimOperationQuestionModule/resetUploadedFile');
    },
    async convertModel() {
      this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
        convertError: false,
        convertProgress: 0,
        convertProgressText: '',
      });
      const res: any = await convertModel(
        {
          fileId: this.$store.state.bimOperationQuestionModule.uploadedFile.fileId,
          tessLevel: this.$store.state.bimOperationQuestionModule.uploadedFile.precision,
        },
        this.$store.state.sessionId,
      );
      if (res.status.error !== 0) {
        console.log('发起转换失败');
        this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
          convertError: true,
        });
        return;
      }
      if (res.convertStatus === 0) {
        console.log('模型已经转换完成');
        this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
          convertProgress: 100,
        });
        return;
      }
      if (res.convertStatus === 1) {
        const timer = setInterval(async () => {
          const convertProgressRes: any = await getConvertProgress(
            { fileId: this.$store.state.bimOperationQuestionModule.uploadedFile.fileId },
            this.$store.state.sessionId,
          );
          if (convertProgressRes.status.error !== 0) {
            console.log('查询转换进度失败');
            this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
              convertError: true,
            });
            clearInterval(timer);
            return;
          }
          if (convertProgressRes.convertStatus === 0) {
            console.log('转换完成');
            this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
              convertProgress: 100,
            });
            clearInterval(timer);
            return;
          }
          if (convertProgressRes.convertStatus === 0) {
            if (
              convertProgressRes.progressDesc
              !== this.$store.state.bimOperationQuestionModule.uploadedFile.convertProgressText
            ) {
              this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
                convertProgress: Math.min(
                  99,
                  this.$store.state.bimOperationQuestionModule.uploadedFile.convertProgress + 10,
                ),
              });
            }
            this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
              convertProgressText: convertProgressRes.progressDesc,
            });
          }
        }, 1000);
      }
    },
    nextStep() {
      this.$store
        .dispatch('bimOperationQuestionModule/createQuestion', {
          typeId: this.$store.state.bimOperationQuestionModule.selectedCatalogue.id,
        })
        .then((createQuestionSuccess) => {
          createQuestionSuccess && this.$emit('toStep', 2);
        });
    },
    nameInput(value) {
      this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
        fileName: value,
      });
    },
    finishUpload() {
      console.log('finish upload and convert');
    },
  },
  watch: {
    '$store.state.bimOperationQuestionModule.uploadedFile': {
      async handler(val, originVal) {
        if (originVal.percentage === '100') {
          return;
        }
        if (val.percentage === '100') {
          this.convertModel();
        }
      },
      deep: true,
    },
    '$store.state.bimOperationQuestionModule.uploadedFile.convertProgress': {
      async handler(val) {
        if (val === 100) {
          getModelImage({
            fileId: this.$store.state.bimOperationQuestionModule.uploadedFile.fileId,
            sessionId: this.$store.state.sessionId,
            callback: (modelImage) => {
              this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
                modelImage,
              });
            },
          });
        }
      },
    },
  },
});
