export const lookArray = array => {
  // 冒泡的次数
  let count = 0;
  // 上一次交换的"气泡"
  let pop = array[0];
  // 利用单次冒泡检验数组需要冒泡的次数
  for (let j = 0; j < array.length - 1; j++) {
    if (array[j] > array[j + 1]) {
      // 判断此次需要向上冒的气泡是否和上次的相同,不同为乱序
      if (pop != array[j]) {
        // 若气泡发生了变化,证明是无序的,停止遍历,返回 0
        return 0;
      }

      let temp = array[j];
      array[j] = array[j + 1];
      array[j + 1] = temp;
      // 两相邻元素交换一次值,count计数一次
      count++;
      pop = array[j + 1];
      //气泡向右移动，可能是逆序，对于逆序序列，还需保证气泡左侧所有的元素大于右侧
      //所以当左侧最小的元素小于右侧元素时，为无序序列
      if (j + 2 < array.length) {
        if (array[j] < array[j + 2]) {
          return 0;
        }
      }
    }
  }
  // 正序返回 1, 倒序返回 -1
  if (count == 0) {
    return 1;
  } else {
    return -1;
  }
};