
import { ElForm } from 'element-ui/types/form';
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: { type: String },
  },
  data() {
    return {
      ruleForm: {
        name: this.name,
      },
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },
    validate(formName: 'createQuestionForm') {
      let res: false | string = false;
      (this.$refs[formName] as ElForm).validate((valid) => {
        if (valid) {
          res = this.ruleForm.name;
        } else {
          res = false;
        }
      });
      return res;
    },
    reset(formName: 'createQuestionForm') {
      (this.$refs[formName] as ElForm).resetFields();
    },
  },
  watch: {
    name: {
      handler(val) {
        this.ruleForm.name = val;
      },
    },
  },
});
