import { render, staticRenderFns } from "./catalogue-list-item.vue?vue&type=template&id=4fc678fd&scoped=true&"
import script from "./catalogue-list-item.vue?vue&type=script&lang=ts&"
export * from "./catalogue-list-item.vue?vue&type=script&lang=ts&"
import style0 from "./catalogue-list-item.vue?vue&type=style&index=0&id=4fc678fd&prod&lang=less&scoped=true&"
import style1 from "./catalogue-list-item.vue?vue&type=style&index=1&id=4fc678fd&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc678fd",
  null
  
)

export default component.exports