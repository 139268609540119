
import Vue from 'vue';
import AttributeDrawer from '@/features/collage/task-detail/attribute-drawer.vue';

export default Vue.extend({
  data() {
    return {
      AttributeDrawerRef: null,
    };
  },
  components: { AttributeDrawer },
  methods: {
    save(data) {
      if (!data) {
        this.$message.error('属性得分百分比必须为100%！');
      } else {
        this.$store
          .dispatch('bimOperationQuestionModule/saveAttributeRule', data)
          .then((saveAttributeRuleSuccess) => {
            if (saveAttributeRuleSuccess) {
              this.$emit('toStep', 5);
            }
          });
      }
    },
    checkAndNext() {
      (this.$refs.AttributeDrawerRef as any).handleSave();
      this.$store.dispatch('bimOperationQuestionModule/configureFinish');
    },
  },
});
