
import { myConfirm } from '@/utils/confirm';
import Vue from 'vue';
import Ellipsis from '../../../components/Ellipsis.vue';
import CreateEditQuestionModal from '../create-edit-question-modal/create-edit-question-modal.vue';

export default Vue.extend({
  components: { Ellipsis, CreateEditQuestionModal },
  props: {
    item: { type: Object },
  },
  computed: {
    isOwn(): boolean {
      return (
        this.$store.state.userInfo.userId === this.item.createUserId || this.$store.getters.isAdmin
      );
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    selectCatalogue() {
      this.$store.commit('bimOperationQuestionModule/setSelectedCatalogue', this.item);
      this.$store.dispatch('bimOperationQuestionModule/getQuestionList', { id: this.item.id });
    },
    editItem() {
      this.dialogVisible = true;
    },
    deleteItem() {
      myConfirm(this, {
        text: '确认删除此目录吗?',
        resolve: () => {
          this.$store
            .dispatch('bimOperationQuestionModule/deleteCatalogueItem', {
              id: this.item.id,
            })
            .then((deleteCatalogueItemSuccess) => {
              deleteCatalogueItemSuccess && this.$message.success('删除目录成功');
            });
        },
      });
    },
  },
});
