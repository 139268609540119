
import { myConfirm } from '@/utils/confirm';
import moment from 'moment';
import Vue from 'vue';
import TaskModelViewer from '@/features/collage/task-detail/task-model-viewer.vue';
import { checkModelConvertStatus } from '@/utils/check-model-convert';
import { getModelByQuestionId, JobType } from '@/api/BimOperationQuestion';
import * as api from '@/api';

export default Vue.extend({
  data() {
    return {
      tableData: [],
      modelViewVisible: false,
      viewFile: {},
    };
  },
  methods: {
    deleteModel(id) {
      myConfirm(this, {
        text: '确认删除该模型?',
        resolve: () => {
          this.$store.dispatch('bimOperationQuestionModule/deleteModel', { id });
        },
      });
    },
    editQuestion(id) {
      this.$store
        .dispatch('bimOperationQuestionModule/getAllRulesByQuestionId', { id })
        .then((getAllRulesByQuestionIdSuccess) => {
          if (getAllRulesByQuestionIdSuccess) {
            this.$store.commit('bimOperationQuestionModule/setCurrentQuestionId', id);
            const questionModel = this.$store.state.bimOperationQuestionModule.modelList.find(
              (v) => v.id === id,
            ).model.uploadFile;
            this.$store.commit('bimOperationQuestionModule/setUploadedFile', {
              fileSize: questionModel.fileSize,
              fileName: questionModel.fileName,
              percentage: '100',
              filePath: questionModel.fileUrl,
              fileId: questionModel.fileId,
              fileExtension: 'rvt',
              error: false,
              modelImage: questionModel.modelThumbnail,
              isUploaded: true,
              convertProgressText: '',
              convertProgress: 100,
              convertError: false,
            });
            this.$store.commit('bimOperationQuestionModule/setCurrentStep', 2);
            this.$store.commit('bimOperationQuestionModule/setConfigureVisible', true);
          }
        });
    },
    async checkModel(id) {
      const res = await getModelByQuestionId({
        taskId: `${id}`,
        isTaskRule: true,
        type: JobType.questionBank,
        skipCount: 0,
        maxResultCount: 999,
      });
      if (res && !api.error(res)) {
        const file = res.data.items[0].uploadFile;
        const convertRes = await checkModelConvertStatus(file.fileId);
        if (convertRes) {
          const { href } = this.$router.resolve({
            path: `/model-view?id=${file.fileId}`,
          });
          window.open(href, '_blank');
        }
      }
    },
    isOwn(createUserId) {
      return this.$store.state.userInfo.userId === createUserId || this.$store.getters.isAdmin;
    },
  },
  filters: {
    formatTime(val) {
      return moment(val)
        .format('YYYY-MM-DD')
        .toLocaleString();
    },
    formatSize(val) {
      if (val) {
        if (val < 1024 * 1024) {
          return `${Math.floor(Number(val) / 1024)} KB`;
        }
        return `${Math.floor(Number(val) / 1024 / 1024)} MB`;
      }
    },
    formatStatus(val) {
      return val === 1 ? '已配置' : '未完成';
    },
  },
  components: { TaskModelViewer },
});
